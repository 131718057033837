const tr = {
    title: '股票計劃諮詢集團LLC',
    article_titel: '最強大的',
    article_titel1: '股票投資',
    article_titel2: '研究社區',
    article_conten: '專業股票推薦社區還有名額!!! 促銷期間限時免費',
    jione: '加入超過5,000位投資者一起做出更明智的投資決策',
    recommendation: '作為一個活躍的投資者，你也會遇到不知道如何開始的時候。這時候你需要一個專業的投資團隊來專業地分析你的情況，並選擇最適合你的交易。',
    paragraph_titel: '成為投資專家',
    paragraph_conten: '學習基本分析的力量。投資是不要讓短期情緒影響你的決策。與我們一起，你可以自信地確信你的投資策略是有效的。',

    paragraph_titel1: 'AI收集並處理信息',
    paragraph_conten1: '我們使用人工智能來搜尋金融網站、公司文件和新聞來源，收集有潛力的股票信息。它以比人類高出100萬倍的處理能力提取與股票價格漲跌相關的數據。尋找財務狀況良好、現金流充足、資產負債表穩健且盈利能力強的公司。',

    paragraph_titel2: '經驗豐富的專業分析團隊',
    paragraph_conten2: '由平均經驗超過20年的分析團隊仔細篩選AI篩選的信息。\n' +
        '一旦我們識別出一些我們認為被低估的公司，就會進行徹底分析來確定它們的內在價值。我們使用一系列估值指標，如市盈率、市淨率和折現現金流分析來估算公司的內在價值。',

    paragraph_titel3: '24/7監控',
    paragraph_conten3: '追踪我們認為是潛在價值股的公司，並隨時監控其表現。\n' +
        '密切關注新聞稿、收益報告和其他相關信息，以確保公司繼續擁有強大的基本面和成長潛力。你知道，AI不需要休息。',

    paragraph_titel4: '我們的社區限時免費。',
    paragraph_conten4: '我們的所有服務都是免費的。\n' +
        '我們希望確保每個人都能獲得市場上最熱股票公司的“熱點新聞”。',

    paragraph_titel5: '放下噱頭和一夜致富的計劃吧！',
    paragraph_conten5: '熱門高收益頂級股票可能為特定個人提供有利的研究機會潛力。\n' +
        '讀者應完全了解自己的風險承受能力，並在做出任何金融決策之前總是諮詢值得信賴的顧問。',

    hearing:'聽聽那些致富的股票交易員的聲音',
    hearing_text:'多年來，明智的股票投資使許多人成為百萬富翁。',

    cat_text:'“我對股票市場還很陌生。到目前為止，我的投資組合幾乎下跌了700%。我閱讀了您過去兩年的股票報告。這次我買了2000股，投資了11,000美元，我一直在閱讀您發送給我的所有信息。我很高興我一直在追隨您的建議，在大跌時買入股票。現在，我增長了18,000美元。太棒了，愛你們大家。”',
    cat_name:'達塞爾·巴倫廷',
    Satisfied_Client:'滿意的客戶',

    cat_text1:'“謝謝你給我這個信號，這對我們尤其是在這個不確定的時期非常有幫助。分享這些而不要求回報，不像其他需要付費的專家，願上帝保佑你。我已經獲得了投入資金的4倍回報。願你的生活充滿祝福。”',
    cat_name1:'吉米·海姆斯沃斯',
    Satisfied_Client1:'滿意的客戶2',

    cat_text2:'“我剛加入，我很感激這一點，我賺了一些錢。我沒有很多錢可以投資，因為我才剛開始，只有10,000美元，但有了這樣的幫助，我已經多賺了8,000美元！！再次感謝你。”',
    cat_name2:'理查德·巴斯廷',
    Satisfied_Client2:'滿意的客戶3',

    btn_text:'加入群組',
    changer:"選擇語言",
    reached:'您已經達到訪問上限'
}
export default tr
