const ja = {
    title: 'ストックプランアドバイザリーグループLLC',
    article_titel: '最強の',
    article_titel1: '株式投資',
    article_titel2: '研究コミュニティ',
    article_conten: 'プロの株式推奨コミュニティ。まだ空きがあります！！！プロモーション期間中、期間限定で無料',
    jione: '5,000人以上の投資家と一緒に、より賢明な投資判断を下しましょう',
    recommendation: 'アクティブな投資家として、どう始めればいいかわからない時もあります。そんな時には、専門の投資チームがあなたの状況をプロフェッショナルに分析し、最適な取引を選んでくれます。',
    paragraph_titel: '投資の専門家になる',
    paragraph_conten: '基本的な分析の力を学びましょう。投資は短期的な感情に左右されずに決定を下すことが重要です。私たちと一緒に、自分の投資戦略が有効であると自信を持ちましょう。',

    paragraph_titel1: 'AIが情報を収集・処理',
    paragraph_conten1: '私たちは、人工知能を使用して、金融ウェブサイト、会社の文書、ニュースソースを精査し、潜在的に価値のある株についての情報を収集しています。人間の100万倍以上の処理能力で株価の上昇と下降に関連するデータを抽出します。財務状況が良好で、キャッシュフローが健全で、バランスシートが強固で、収益性の高い企業を探します。',

    paragraph_titel2: '経験豊富なプロの分析チーム',
    paragraph_conten2: 'AIが精査した情報は、平均20年以上の経験を持つ分析チームによって慎重に選別されます。\n' +
        '割安と考えられる複数の企業を特定したら、それらの内在価値を判断するために徹底的な分析を行います。株価収益率、株価純資産倍率、割引キャッシュフロー分析などの評価指標を使用して、企業の内在価値を見積もります。',

    paragraph_titel3: '24/7監視',
    paragraph_conten3: '潜在的な価値株として特定した企業を追跡し、時間とともにそのパフォーマンスを監視します。\n' +
        'プレスリリース、収益報告、およびその他の関連情報を注意深く監視し、企業が引き続き強固な基本的要素と成長の可能性を持っていることを確認します。ご存知のように、AIは休む必要がありません。',

    paragraph_titel4: '私たちのコミュニティは期間限定で無料です。',
    paragraph_conten4: 'すべてのサービスは無料です。\n' +
        '市場で最もホットな株式企業の「ホットニュース」に誰もがアクセスできるようにしたいと考えています。',

    paragraph_titel5: 'トリックや一攫千金のスキームはもうやめましょう！',
    paragraph_conten5: 'ホットな高利回りのトップ株は、特定の個人にとって有益な研究機会の可能性を提供します。\n' +
        '読者は、自身のリスク許容度を十分に理解し、金融の決定を行う前に必ず信頼できるアドバイザーに相談するべきです。',

    hearing:'財を成した株式トレーダーからの声',
    hearing_text:'長年にわたり、賢明な株式投資は多くの人々を億万長者にしました。',

    cat_text:'“私は株式市場には比較的新しいです。これまで私のポートフォリオはほぼ700％減少していました。あなたの株式レポートを過去2年間読み、このたび2000株を購入し、11,000ドルを投資しました。送ってくれるすべての情報を読んでいます。あなたの推奨をフォローし、大きな下落時に株を購入し続けて本当に良かったです。今、18,000ドル増えました。素晴らしい仕事をありがとう、皆さん大好きです。”',
    cat_name:'ダーセル・バレンタイン',
    Satisfied_Client:'満足したクライアント',

    cat_text1:'“このシグナルを教えてくれてありがとう、この不確実な時期に特に助かります。お返しを求めずにシェアしてくれて、他のグルたちのように料金を払う必要がありません。神のご加護がありますように。すでに投資額の4倍の利益を得ました。あなたの人生に祝福が訪れますように。”',
    cat_name1:'ジミー・ヘムズワース',
    Satisfied_Client1:'満足したクライアント2',

    cat_text2:'“入ってすぐにお金を稼げました。投資する金額は多くありませんが、たった10,000ドルから始めましたが、あなたのおかげで既に8,000ドル増えました！本当にありがとうございます。”',
    cat_name2:'リチャード・バスティン',
    Satisfied_Client2:'満足したクライアント3',

    btn_text:'グループに参加する',
    changer:"言葉を選びます",
    reached:'アクセス上限に達しました'
}
export default ja
