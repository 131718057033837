const en = {
    title: 'Stock Plan Advisory Group LLC',
    article_titel: 'The Most Powerful',
    article_titel1: 'Stock Investment',
    article_titel2: 'Eesearch Communit',
    article_conten: 'Professional Stock Recommendation Community Places are still available!!! Free for a limited time during the promotion period',
    jione: 'Join 5,000+ Investors Like You Making Smarter Investing Decisions',
    recommendation: 'As an active investor, you will also encounter times when you don\'t know how to get started. This is when you need a professional investment team to analyze your situation professionally and choose the most suitable trades for you.',
    paragraph_titel: 'Become An Investing Expert',
    paragraph_conten: 'Learn the power of fundamental analysis. Investing is about not letting short term emotions impact your decisions. With us you can gain the confidence that your investing strategy works.',

    paragraph_titel1: 'AI COLLECTS AND PROCESSES INFORMATION',
    paragraph_conten1: 'We use artificial intelligence to scour financial websites, company documents, and news sources to gather information about potentially valuable stocks.It Extracts Data Related To The Rise And Fall Of Stock Prices With A Processing Power More Than 1 Million Times That Of Humans. Look For Companies With Strong Financials, Good Cash Flow, A Solid Balance Sheet, And Good Profitability.',

    paragraph_titel2: 'EXPERIENCED PROFESSIONAL ANALYST TEAM',
    paragraph_conten2: 'The information screened by AI is carefully selected by an analysis team with an average experience of more than 20 years.\n' +
        'Once We\'ve Identified Several Companies That We Believe Are Undervalued, A Thorough Analysis Is Performed To Determine Their Intrinsic Value. We Estimate A Company\'s Intrinsic Value Using A Range Of Valuation Metrics, Such As Price-To-Earnings, Price-To-Book, And Discounted Cash Flow Analysis.',

    paragraph_titel3: '24/7 MONITORING',
    paragraph_conten3: 'Track companies we\'ve identified as potential value stocks and monitor their performance over time.\n' +
        'Keep A Close Eye On Press Releases, Earnings Reports And Other Relevant Information To Ensure The Company Continues To Have Strong Fundamentals And Growth Potential. You Know, AI Doesn\'t Need To Rest.',

    paragraph_titel4: 'OUR COMMUNITY IS FREE FOR A LIMITED TIME.',
    paragraph_conten4: 'All our services are free.\n' +
        'We Want To Make Sure Everyone Has Access To The "Hot News" Of The Hottest Stock Companies In The Market.',

    paragraph_titel5: 'LEAVE THE GIMMICKS AND GET-RICH SCHEMES AT THE DOOR!',
    paragraph_conten5: 'Hot high-yield top stocks can present lucrative research opportunity potential for certain individuals.\n' +
        'Readers Should Fully Understand Their Own Risk Tolerance And Always Consult A Trusted Advisor Before Making Any Financial Decisions.',

   hearing:'Hear From Stock Traders Who Have Made a Fortune',
   hearing_text:'Over the years, smart stock investing has made many people millionaires.',

    cat_text:'“I\'m fairly new to the stock market. So far my portfolio was down almost 700% I read your stock reports for the last 2 years.And this time I bought 2000 shares and invested $11,000 ,and I\'ve been reading everything you send me. I am so glad that I have been following your recommendations and been buying shares on the big dips. I now am up $18,000. Fantastic job love you folks.”',
    cat_name:'Darcel Ballentine',
    Satisfied_Client:'Satisfied Client',

    cat_text1:'“Thank you for the giving me this signal it will help us a lot especially during this time of uncertainty. Sharing this without even asking in return, not like any others gurus that you need to pay, God bless you I have already made 4x what I put in. Blessings upon blessing upon your life.”',
    cat_name1:'Jimmy Hemsworf',
    Satisfied_Client1:'Satisfied_Client2',

    cat_text2:'“I just got in and I am grateful for this I made some money. I don’t have a lot to invest cause I just started, just $10,000 but you are truly making that change with help like this and I am already up by another $8,000!! Thank you again.”',
    cat_name2:'Richard Bastin',
    Satisfied_Client2:'Satisfied_Client3',

    btn_text:'Join The Group',
    changer:"Select language",
    reached:'You have reached your access limit'
}
export default en