// 路由配置
import {createRouter, createWebHistory} from 'vue-router';

// 定义路由
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/HelloWorld.vue'),
    },
    {
        path: '/LoginPage',
        name: 'LoginPage',
        component: () => import('@/components/LoginPage.vue'),
    },
    {
        path: '/LinkPage',
        name: 'LinkPage',
        component: () => import('@/components/LinkPage.vue'),
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/components/test1.vue'),
    },
    {
        path: '/404',
        name: '404Page',
        component: () => import('@/components/404Page.vue'),
    }
    // 更多路由...
];

// 创建路由实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 路由守卫

router.beforeEach(async (to, from, next) => {
    // 从 localStorage 获取登录状态
    const loginState = localStorage.getItem("loginState") === 'true';

    // 从 localStorage 获取访问次数
    const countStr = localStorage.getItem("count");
    const count = countStr ? parseInt(countStr, 10) : 0;
    if (loginState) {
        next();
    } else {
        if (to.path === '/') {
            if (count <= 3) {
                next();
            } else {
                next('/404');
            }
        } else if (to.path === '/LoginPage') {
            next();
        } else if (to.path === '/LinkPage') {
            next('/LoginPage');
        }
        next();
    }

});
export default router;
